<script>
import faqDropIcon from '../../../assets/SVG/faq-drop-icon.svg'

export default {
  name: 'FaqDrop',
  props: {
    faq: {
      required: true
    }
  },
  data() {
    return {
      faqDropIcon
    }
  }
}
</script>

<template>
  <div class="faq-drop-item faq-drop-border">
    <div class="faq-drop-item-title-container">
      <span class="faq-drop-item-title">{{ faq.title }}</span>
      <img :src="faqDropIcon" class="faq-drop-item-toggle" />
    </div>
    <p class="faq-drop-item-detail">{{ faq.detail }}</p>
  </div>
</template>

<style scoped lang="scss">
.faq-drop-border:not(:last-of-type) {
  padding-bottom: 24px;
  border-bottom: 0.5px solid #959595;
  margin-bottom: 24px;
}

.faq-drop-item-title-container {
  display: flex;
  justify-content: space-between;
}

.faq-drop-item-title {
  color: #2c2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
}

.faq-drop-item-detail {
  color: #959595;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144%; /* 23.04px */
  max-width: 75%;
  margin-top: 24px;
}

.faq-drop-item-toggle {
  margin-left: 10px;
}
</style>
