<template>
  <div class="sleek-toggle-container">
    <div
      class="sleek-toggle-option sleek-toggle-option-left"
      :class="[isLeftSelected ? 'sleek-toggle-option-active' : '']"
      @click="toggle('left')"
    >
      <slot name="left"></slot>
      <span v-if="$slots.leftBadge" class="sleek-toggle-badge"><slot name="leftBadge"></slot></span>
    </div>
    <div
      class="sleek-toggle-option sleek-toggle-option-right"
      :class="[isRightSelected ? 'sleek-toggle-option-active' : '']"
      @click="toggle('right')"
    >
      <span><slot name="right"></slot></span>
      <span v-if="$slots.rightBadge" class="sleek-toggle-badge"
        ><slot name="rightBadge"></slot
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SleekToggle',
  props: {
    defaultOption: {
      required: true
    }
  },
  emits: ['toggle'],
  data() {
    const currentSelection = this.defaultOption

    return {
      currentSelection
    }
  },
  computed: {
    isLeftSelected() {
      return this.currentSelection === 'left'
    },
    isRightSelected() {
      return this.currentSelection === 'right'
    }
  },
  methods: {
    toggle(side) {
      this.currentSelection = side
      this.$emit('toggle', side)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.sleek-toggle-container {
  display: inline-flex;
  border-radius: 5px;
  border: 2px solid #f6f6f6;
  background: #fbfcfd;
  padding: 5px;
}

.sleek-toggle-option {
  background: none;
  color: #959595;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
}

.sleek-toggle-option-active {
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  background: #ccf0dd;
  color: #10be5e;
}

.sleek-toggle-badge {
  border-radius: 10px;
  border: 1px solid #10be5e;
  background: #fff;
  color: #10be5e;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 7.523px */
  padding: 4px 5px;
  text-transform: uppercase;
  margin-left: 8px;
}
</style>
