<template>
  <scroll-container id="autocost-product-page">
    <div id="autocost-header-container">
      <container
        class="center"
        style="height: 100%; position: relative; flex-direction: row; flex-wrap: auto"
        size="7xl"
      >
        <div class="ac-header-text" style="flex: 1">
          <div class="ac-header-main-text">
            <p class="ac-new-feature-highlight">New Feature</p>
            <p class="ac-header-title">Cloud Office Manager</p>
            <p class="ac-header-subtitle">
              Virtual receptionists, project coordinators, and assistants - all trained and
              connected into your account. A dedicated personal touch to answer your calls, help
              your customers or subcontractors, send proposals, follow up on quotes and invoices.
              <br />
              <br />
              Starting at $2,000 per month
              <br />
            </p>
            <div class="ac-header-buttons">
              <SleekButton class="ac-header-button" :primary="false" @click="scrollToPricing">
                {{ moduleButtonText }}
              </SleekButton>
              <SleekButton
                class="ac-header-button"
                :invert="true"
                @click="openInNewTab('https://calendly.com/dakotahines/cloud-office-manager')"
              >
                Contact sales
              </SleekButton>
            </div>
          </div>
          <div class="ac-header-gotcha">
            <img :src="infoImage" />
            <span>Available in all regions. Pricing in USD.</span>
          </div>
        </div>
        <font-awesome-icon
          icon="headset"
          style="font-size: 20em; margin-left: 50px; flex: 1"
          class="light hidden md:flex"
        />
      </container>
    </div>
    <div id="autocost-content-container">
      <container size="7xl">
        <div id="autocost-features-container" class="ac-section">
          <div id="autocost-features-hero">
            <p class="ac-section-title">Features and benefits</p>
            <p class="ac-features-hero-subtitle ac-text-light">
              Cloud Office Managers gives you someone to answer your calls, respond to emails, and
              manage customer queries.
            </p>
          </div>
          <div class="ac-benefits-container">
            <div class="ac-benefits-block ac-bg-darker ac-rounded-large">
              <font-awesome-icon icon="phone-alt" style="font-size: 5em; margin-bottom: 0.25em" />
              <p class="ac-benefits-block-title">Your full-time receptionist</p>
              <p class="ac-benefits-block-detail ac-text-light">
                Having someone to answer your calls, book client meetings and be available to your
                customers is how you provide that next-level service after they've approved your
                Bolster quote.
              </p>
            </div>
            <div class="ac-benefits-block ac-bg-darker ac-rounded-large">
              <font-awesome-icon icon="calendar" style="font-size: 5em; margin-bottom: 0.25em" />
              <p class="ac-benefits-block-title">Keep the money and work flowing</p>
              <p class="ac-benefits-block-detail ac-text-light">
                Let them manage your schedule, do your accounts receivables, and make sure every
                invoice is followed up on and that payments are made on time.
              </p>
            </div>
            <div class="ac-benefits-block ac-bg-darker ac-rounded-large">
              <font-awesome-icon icon="paper-plane" style="font-size: 5em; margin-bottom: 0.25em" />
              <p class="ac-benefits-block-title">Respond to emails within minutes</p>
              <p class="ac-benefits-block-detail ac-text-light">
                Let your assistant sort through the important emails for you, responding to
                customers and subcontractors quickly. They will reach out to you right away for
                anything important.
              </p>
            </div>
            <div class="ac-benefits-block ac-bg-darker ac-rounded-large">
              <font-awesome-icon icon="user" style="font-size: 5em; margin-bottom: 0.25em" />
              <p class="ac-benefits-block-title">Techincal support for you and your customers</p>
              <p class="ac-benefits-block-detail ac-text-light">
                Help customers with their questions, resending proposals, invoices and helping them
                approve change orders. Taking notes and sending you the details when it's not
                mission critical.
              </p>
            </div>
          </div>
        </div>
        <div id="autocost-hiw-container" class="ac-section ac-bg-darker ac-rounded-large">
          <p class="ac-section-title ac-mb-56">How it works</p>
          <div id="autocost-hiw-steps">
            <div class="autocost-hiw-step">
              <div class="ac-hiw-step-number">1</div>
              <div class="ac-hiw-step-detail ac-text-light">
                We match you with your virtual office managers based on your needs. They will get
                everything they need to be your office manager, including computer, internet, phone.
                We give them a dedicated phone number, or can route your existing number to them.
              </div>
            </div>
            <div class="autocost-hiw-step">
              <div class="ac-hiw-step-number">2</div>
              <div class="ac-hiw-step-detail ac-text-light">
                Your office managers are trained on Bolster, and are connected to your account. They
                can access your account as if they were your employee but you don't need to worry
                about payroll, HR, an office, a desk or a computer.
              </div>
            </div>
            <div class="autocost-hiw-step">
              <div class="ac-hiw-step-number">3</div>
              <div class="ac-hiw-step-detail ac-text-light">
                They are up and running as long as you need them. They are dedicated to your company
                at a fraction of the price it would cost locally.
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row flex-space-between flex-wrap">
          <div
            id="autocost-pricing"
            class="ac-bordered ac-section ac-rounded-large mr-1"
            ref="acPricing"
          >
            <p class="ac-section-title">Business hours</p>
            <SleekToggle
              v-if="!isModuleEnabled"
              class="ac-pricing-toggle"
              default-option="right"
              @toggle="togglePricingBusinessHours"
            >
              <template #left>Fractional</template>
              <template #right>Dedicated</template>
              <template #rightBadge>Save 25%</template>
            </SleekToggle>
            <div
              id="ac-monthly-pricing"
              class="ac-pricing-display"
              v-if="selectedPricing === 'month'"
            >
              <p class="ac-pricing-primary">coming soon</p>
            </div>
            <div id="ac-annual-pricing" class="ac-pricing-display" v-else>
              <p class="ac-pricing-primary">$2,000 / month / agent</p>
            </div>
            <div class="ac-pricing-info-well ac-bordered ac-bg-darker ac-text-light">
              <span class="ac-info-well-title">Why Cloud Office Managers?</span>
              <p class="ac-info-well-detail">A virtual receptionist at a fraction of the cost.</p>
              <ul class="ac-info-well-list">
                <li>Connected to your account</li>
                <li>Fully managed for you</li>
                <li>Well-trained native English speakers</li>
              </ul>
            </div>
            <SleekButton v-if="!isModuleEnabled && userCanEnableModule" @click="openTermsModal">
              {{ moduleButtonText }}
            </SleekButton>
            <div
              v-else-if="isModuleEnabled && isUserLoggedIn && isCompanyScoped"
              class="ac-pricing-active-subscription"
            >
              <div class="ac-subscription-status">
                <p class="ac-activation-status">Activated{{ inFreeTrial ? ' (Trial)' : '' }}</p>
                <p class="ac-activation-term ac-text-light">
                  Subscription Term: {{ subscriptionTerm }}
                </p>
                <p v-if="inFreeTrial" class="ac-activation-trial-time ac-text-light">
                  Trial Ends: {{ freeTrialEndDateFormatted }}
                </p>
              </div>
              <SleekButton @click="cancelProduct" v-if="userCanEnableModule">Cancel</SleekButton>
            </div>
            <div
              v-else-if="isUserLoggedIn && !isCompanyScoped"
              class="ac-companies-list ac-text-light"
            >
              <p id="ac-companies-list-title">Select a company:</p>
              <div
                v-for="(company, index) in companiesAvailable"
                :key="index"
                class="ac-available-company"
              >
                <p @click="goToCompany(company.route)">{{ company.company_name }}</p>
              </div>
            </div>
            <div v-else>
              <SleekButton @click="activateProduct"> Contact sales </SleekButton>
            </div>
          </div>

          <div id="autocost-pricing" class="ac-bordered ac-section ac-rounded-large ml-1">
            <p class="ac-section-title">24/7 coverage</p>
            <SleekToggle
              v-if="!isModuleEnabled"
              class="ac-pricing-toggle"
              default-option="right"
              @toggle="togglePricing247"
            >
              <template #left>Fractional</template>
              <template #right>Dedicated</template>
              <template #rightBadge>Save 20%</template>
            </SleekToggle>
            <div
              id="ac-monthly-pricing"
              class="ac-pricing-display"
              v-if="selectedPricing === 'month'"
            >
              <p class="ac-pricing-primary">coming soon</p>
            </div>
            <div id="ac-annual-pricing" class="ac-pricing-display" v-else>
              <p class="ac-pricing-primary">$10,000 / month</p>
            </div>
            <div class="ac-pricing-info-well ac-bordered ac-bg-darker ac-text-light">
              <span class="ac-info-well-title">Why Cloud Office Managers?</span>
              <p class="ac-info-well-detail">A virtual receptionist at a fraction of the cost.</p>
              <ul class="ac-info-well-list">
                <li>Connected to your account</li>
                <li>Fully managed for you</li>
                <li>Well-trained native English speakers</li>
              </ul>
            </div>
            <SleekButton v-if="!isModuleEnabled && userCanEnableModule" @click="openTermsModal">
              {{ moduleButtonText }}
            </SleekButton>
            <div
              v-else-if="isModuleEnabled && isUserLoggedIn && isCompanyScoped"
              class="ac-pricing-active-subscription"
            >
              <div class="ac-subscription-status">
                <p class="ac-activation-status">Activated{{ inFreeTrial ? ' (Trial)' : '' }}</p>
                <p class="ac-activation-term ac-text-light">
                  Subscription Term: {{ subscriptionTerm }}
                </p>
                <p v-if="inFreeTrial" class="ac-activation-trial-time ac-text-light">
                  Trial Ends: {{ freeTrialEndDateFormatted }}
                </p>
              </div>
              <SleekButton @click="cancelProduct" v-if="userCanEnableModule">Cancel</SleekButton>
            </div>
            <div
              v-else-if="isUserLoggedIn && !isCompanyScoped"
              class="ac-companies-list ac-text-light"
            >
              <p id="ac-companies-list-title">Select a company:</p>
              <div
                v-for="(company, index) in companiesAvailable"
                :key="index"
                class="ac-available-company"
              >
                <p @click="goToCompany(company.route)">{{ company.company_name }}</p>
              </div>
            </div>
            <div v-else>
              <SleekButton @click="activateProduct"> Contact sales </SleekButton>
            </div>
          </div>
        </div>
        <div id="autocost-faq" class="ac-section ac-bg-darker ac-rounded-large">
          <p class="ac-section-title ac-mb-56">FAQ</p>
          <FaqDrop :key="item.id" v-for="item in faqItems" :faq="item" />
        </div>
      </container>
    </div>
    <mini-modal ref="termsModal">
      <div class="ac-terms-checkboxes">
        <div class="product-card-terms-checkbox">
          <checkbox
            v-model="acceptedTermsOfService"
            :boolean="true"
            :selected-value="true"
            :deselected-value="false"
            class="inline info xs mr-3"
          />
          <span>I agree and accept</span>
          <span
            class="product-card-terms-link"
            @click="openInNewTab('https://costcertified.com/legal/terms-and-conditions')"
            >Terms of Service</span
          >
        </div>
        <div class="product-card-terms-checkbox">
          <checkbox
            v-model="acceptedPrivacyPolicy"
            :boolean="true"
            :selected-value="true"
            :deselected-value="false"
            class="inline info xs mr-3"
          />
          <span>I agree and accept</span>
          <span
            class="product-card-terms-link"
            @click="openInNewTab('https://costcertified.com/legal/privacy-policy')"
            >Privacy Policy</span
          >
        </div>
      </div>
      <div class="ac-terms-actions">
        <SleekButton @click="closeTermsModal">Back</SleekButton>
        <SleekButton @click="activateProduct">Accept & Continue</SleekButton>
      </div>
    </mini-modal>
  </scroll-container>
</template>

<script>
import FaqDrop from '@/components/ui/modern/FaqDrop.vue'
import SleekButton from '@/components/ui/modern/SleekButton.vue'
import SleekToggle from '@/components/ui/modern/SleekToggle.vue'

import hiwImage from '../../assets/pages/product/autocost/how-it-works.svg'
import hourglassImage from '../../assets/pages/product/autocost/hourglass.svg'
import updateImage from '../../assets/pages/product/autocost/update.svg'
import flagImage from '../../assets/pages/product/autocost/flag.svg'
import brickImage from '../../assets/pages/product/autocost/bricks.svg'
import infoImage from '../../assets/pages/product/autocost/info.svg'
import headerImage from '../../assets/pages/product/autocost/header.svg'

export default {
  components: {
    SleekButton,
    SleekToggle,
    FaqDrop
  },
  data() {
    const faqItems = [
      {
        title: 'Can I choose who to have as my agent?',
        detail:
          "Yes, when you have a dedicated Cloud Office Manager you are able to select them and change them if it isn't working."
      },
      {
        title: 'What sort of things can they do for me?',
        detail:
          'They can answer your calls, emails, make outbound calls to your specifications, help you coordinate work, send other email requests on your behalf, book meetings for you, call or receive calls from your leads.'
      },
      {
        title: 'Do they speak English?',
        detail:
          'Yes they are all native English speakers with mild South African accents and are based in beautiful Cape Town, South Africa - and they work during your business hours.'
      },
      {
        title: 'What is included?',
        detail:
          'Included with every Cloud Office Manager subscription is a dedicated phone number and email. We handle all the office space, desk, HR management, payroll and insurance.'
      },
      {
        title: 'What hours do they work?',
        detail:
          'If you choose 24/7 availability, you will have multiple agents covering all day and night.  If you want business hours only, they will work during your business hours. Contact us for arrangements outside of normal business hours but not quite 24/7.'
      },
      {
        title: 'Can they access my Bolster account?',
        detail:
          'Yes, they will be able to access your account as if they were your employee. They are fully trained on Bolster and will also act like a full time dedicated techincal support for you.'
      },
      {
        title: 'Can they replace my office manager/coordinator/receptionist?',
        detail:
          'Combined with Bolster automations, it is very likely that a Cloud Office Manager could entirely replace your current office manager saving you tens of thousands a year.'
      }
    ]
    const selectedPricing = 'year'
    const isModuleEnabled = false
    const freeTrialStartUnix = null
    const freeTrialEndUnix = null
    const freeTrialStarted = false
    const activationLoading = 0
    const isAcceptingTerms = false
    const acceptedTermsOfService = false
    const acceptedPrivacyPolicy = false

    return {
      faqItems,
      selectedPricing,
      isModuleEnabled,
      freeTrialStartUnix,
      freeTrialEndUnix,
      freeTrialStarted,
      activationLoading,
      isAcceptingTerms,
      acceptedTermsOfService,
      acceptedPrivacyPolicy,
      hiwImage,
      hourglassImage,
      updateImage,
      flagImage,
      brickImage,
      infoImage,
      headerImage
    }
  },
  computed: {
    inFreeTrial() {
      if (!this.freeTrialStarted) return false
      const now = new Date()
      const currentUnix = now.getTime()
      return this.freeTrialEndUnix > currentUnix
    },
    freeTrialEndDateFormatted() {
      if (!this.freeTrialEndUnix) return ''
      const endDate = new Date(this.freeTrialEndUnix)
      const options = { day: '2-digit', year: 'numeric', month: 'short' }
      return `${endDate.toLocaleDateString('en-US', options)}`
    },
    isSuperUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    },
    subscriptionTerm() {
      return this.selectedPricing === 'year' ? 'Yearly' : 'Monthly'
    },
    isUserLoggedIn() {
      return this.$store.state.session.isLoggedIn
    },
    isCompanyScoped() {
      return this.$store.state.session.scope.company
    },
    isCompanyUS() {
      const session = this.$store.state.session
      return session.company && session.company.country_id === 2
    },
    isUserCompanyAdmin() {
      const session = this.$store.state.session
      return session.user && session.user.user_is_admin === 1
    },
    moduleButtonText() {
      if (this.isModuleEnabled) {
        return 'Activated'
      }

      if (this.freeTrialStarted) {
        return this.inFreeTrial ? 'Resume trial' : 'Reactivate'
      }

      return 'Get started now'
    },
    companiesAvailable() {
      return this.$store.state.session.scopableObjects.company
    },
    userCanEnableModule() {
      // Conditions for enabling module:
      return (
        this.isUserLoggedIn &&
        this.isCompanyScoped &&
        this.isCompanyUS &&
        (this.isUserCompanyAdmin || this.isSuperUser)
      )
    }
  },
  methods: {
    togglePricing247(side) {
      this.selectedPricing = side === 'left' ? 'fractional-247' : 'dedicated-247'
    },
    togglePricingBusinessHours(side) {
      this.selectedPricing =
        side === 'left' ? 'fractional-business-hours' : 'dedicated-business-hours'
    },
    openTermsModal() {
      this.$refs.termsModal.open()
    },
    closeTermsModal() {
      this.$refs.termsModal.close()
    },
    openInNewTab(url) {
      window.open(url)
    },
    scrollToPricing() {
      c.scrollTo(this.$refs.acPricing)
    },
    goToCompany(route) {
      this.$router.push(`/${route}/com`)
    },
    async getSubscriptionStatus() {
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: 'subscription/getProductSubscriptionStatus',
          data: {
            module_id: 27,
            module_name: 'CloudOfficeManager',
            company_id: this.$store.state.session.company.company_id
          }
        })
        this.isModuleEnabled = payload.is_module_enabled
        if (this.isModuleEnabled) {
          this.selectedPricing = payload.payment_schedule
        }
        this.freeTrialStartUnix = payload.free_trail_start_date
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
    },
    async cancelProduct() {
      if (this.activationLoading) return
      this.activationLoading = 1
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: 'subscription/cancelProductSubscription',
          data: {
            module_id: 27,
            module_name: 'CloudOfficeManager',
            company_id: this.$store.state.session.company.company_id
          }
        })
        this.isModuleEnabled = payload.is_module_enabled
        this.freeTrialStartUnix = payload.free_trail_start_date
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
      this.activationLoading = 0
    },
    async activateProduct() {
      this.openInNewTab('https://calendly.com/dakotahines/cloud-office-manager')
    }
  },
  watch: {
    isCompanyScoped(val) {
      if (val) this.getSubscriptionStatus()
    }
  },
  async mounted() {
    this.loading = 1
    if (this.isUserLoggedIn && this.isCompanyScoped) {
      await this.getSubscriptionStatus()
    }
    this.loading = 0
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
#autocost-product-page {
  background-color: #fff;
}

#autocost-content-container {
  padding: 0 24px;

  @media (min-width: 768px) {
    padding: 0 100px;
  }
}

#autocost-header-container {
  width: 100%;
  height: 100vh;
  background-color: #10be5e;
  background-repeat: no-repeat;
  color: $flame-white;

  @media (min-width: 576px) {
    height: 66vh;
    padding: 0 100px;
    background-position: right 100px center;
    background-size: auto;
  }

  @media (max-width: 576px) {
    background-image: none !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    background-position: right 100px bottom 32px;
    background-size: 40%;
  }
}

#autocost-features-hero {
  margin-bottom: 96px;
}

#autocost-features-container {
  width: 100%;
}

#autocost-hiw-container {
  padding: 32px;
  text-align: center;

  @media (min-width: 992px) {
    padding: 105px;
  }
}

#autocost-hiw-steps {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

#autocost-pricing {
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 32px;

  @media (min-width: 576px) {
    padding: 96px 105px;
    flex: 1 45%;
  }
  @media (max-width: 576px) {
    flex: 1 100%;
  }
}

#autocost-faq {
  padding: 32px;

  @media (min-width: 992px) {
    padding: 96px 210px;
  }
}

.ac-section {
  margin-top: 96px;
}

.ac-bg-darker {
  background-color: #fbfcfd;
}

.ac-header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.ac-new-feature-highlight {
  color: #cff2df;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
  text-transform: uppercase;
}

.ac-header-title {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 70.4px */
  margin: 24px 0;
}

.ac-header-subtitle {
  color: #cff2df;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
}

.ac-header-button {
  margin-top: 32px;
}

@media (min-width: 576px) {
  .ac-header-button:not(:last-of-type) {
    margin-right: 10px;
  }

  .ac-header-button:not(:first-of-type) {
    margin-left: 10px;
  }
}

.ac-header-gotcha {
  display: flex;
  color: #cff2df;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: -0.2px;
}

.ac-header-gotcha > img {
  margin-right: 12px;
}

.ac-hiw-step-number {
  width: 64px;
  height: 64px;
  display: block;
  background-color: #ccf0dd;
  border-radius: 99999px;
  color: #10be5e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  vertical-align: middle;
  margin-bottom: 24px;
}

.ac-hiw-step-detail {
  text-align: left;
}

.ac-hiw-image {
  margin-bottom: 56px;
  width: 100%;
}

.ac-section-title {
  color: #2c2b2b;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 52.8px */
  margin-bottom: 18px;
}

.ac-benefits-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
}

.ac-benefits-block {
  padding: 32px;

  @media (min-width: 992px) {
    padding: 64px 105px;
  }
}

.ac-benefits-block-title {
  color: #2c2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
}

.ac-benefits-block-detail {
  margin-top: 24px;
}

.ac-benefits-block-icon {
  height: 64px;
  aspect-ratio: 1/1;
  margin-bottom: 24px;
}

.ac-features-hero-subtitle {
  max-width: 540px;
  text-align: center;
  margin: 0 auto;
  font-size: 0.8em;
}

.ac-pricing-info-well {
  border-radius: 10px;
  text-align: center;
  margin-bottom: 24px;
  padding: 32px;

  @media (min-width: 768px) {
    padding: 35px 57px;
  }
}

.ac-info-well-title {
  margin-bottom: 12px;
  font-weight: 600;
}

.ac-info-well-list {
  margin-top: 24px;
}

.ac-info-well-list li:before {
  content: '*';
  margin-right: 10px;
}

.ac-pricing-display {
  margin: 56px auto;
}

.ac-pricing-primary {
  color: #2c2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}

.ac-pricing-secondary {
  min-height: 24px;
}

.ac-terms-actions {
  margin-top: 24px;
  text-align: center;
}

.ac-activation-status {
  color: #10be5e;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  margin-bottom: 12px;
}

.ac-subscription-status {
  margin-bottom: 24px;
}

.ac-bordered {
  border: 2px solid #f6f6f6;
}

.ac-mb-56 {
  margin-bottom: 56px;
}

.ac-rounded-large {
  border-radius: 20px;
}

.ac-text-light {
  color: #959595;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 144%; /* 23.04px */
}

#ac-companies-list-title {
  margin-bottom: 12px;
}

.ac-available-company {
  color: #10be5e;
  font-size: 18px;
  line-height: 144%;
  cursor: pointer;
}

.ac-available-company:not(:first-of-type) {
  margin-top: 12px;
}

.product-card-terms-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5em;

  @media (max-width: 576px) {
    font-size: 0.9em;
  }

  .product-card-terms-link {
    color: #1f92fc;
    margin-left: 0.3em;
    cursor: pointer;
    &:hover {
      color: #11487a;
    }
  }
}
</style>
